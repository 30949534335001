/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,  
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Slide,  
  Grid
} from "@material-ui/core";

import clsx from "clsx";
import {useStyles} from "./index.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCameraCctv, faFilmSlash, faImage} from "@fortawesome/pro-duotone-svg-icons";
import LPRImage from "./LPRImage";
import apiClient from "../../../../auth/apiClient";
import LPRService from "../../../../services/LPRService.js";

const LPRImageDialog = ({lprID, lprPlateText, toOpen, onClose}) => {
  const classes = useStyles();
  const [context, setContext] = useState("context");
  const [open, setOpen] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(true);
  const [updatedPlateText, setUpdatedPlateText] = useState(lprPlateText);
  const [isEditable, setIsEditable] = useState(false);
  const [savedPlateText, setSavedPlateText] = useState(lprPlateText);

  useEffect(() => {
    setImageRemoved(lprPlateText?.toUpperCase() === "REMOVED");
  }, [lprPlateText]);

  useEffect(() => {
    if (toOpen) {
      setOpen(true);
    }
  }, [toOpen]);

  useEffect(() => {
    setUpdatedPlateText(lprPlateText);
    setSavedPlateText(lprPlateText);
  }, [lprPlateText]);

  const handleClose = (e) => {
    setOpen(false);
    setContext("context");
    onClose();
  }

  const lprService = new LPRService(apiClient);
  const handleSave = async () => {
    if (!updatedPlateText.trim()) {
      setIsEditable(false);
      return;
    }
    if (updatedPlateText.trim() === savedPlateText) {      
      setIsEditable(false);
      return;
    }
    try {
       lprService.updateCameraRead(lprID, updatedPlateText);      
        setSavedPlateText(updatedPlateText);
        setIsEditable(false);
        return;
    }catch (error) {
      console.error("Failed to update plate text", error);
    }
  };

  const handleCancel = () => {
    setUpdatedPlateText(savedPlateText);
    setIsEditable(false);
  }; 

  return (
    <>
      <Dialog className={clsx(["lpr-image-container"])}
        fullScreen
        fullWidth
        keepMounted
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
      >
        <DialogTitle>
          <>
            <span className={clsx(["title", classes.title, imageRemoved ? "dontshow" : ""])}>License Plate</span>
            {(isEditable && !imageRemoved) ? (
            <div style={{ overflow: 'hidden' }}>
              <Slide direction="left" in={isEditable} mountOnEnter unmountOnExit>
                <Grid container  >
                  <Grid item xs={12} sm={12} >
                    <Button
                      name="cancel"
                      variant="contained"
                      onClick={handleCancel}
                      className={clsx("cancelBtn", classes.btnControl)}
                    >
                      Cancel
                    </Button>
                    <Button
                      name="save"
                      color="primary"
                      variant="contained"
                      onClick={handleSave}
                      className={clsx("saveBtn", classes.btnControl)}
                      disabled={!updatedPlateText.trim()}
                    >
                      Save
                    </Button>
                    <TextField
                      anchor="right"
                      variant="outlined"
                      InputProps={{
                        inputProps:{
                          maxLength: 20
                        }
                      }}
                      value={updatedPlateText}
                      onChange={(e) => setUpdatedPlateText(e.target.value)}
                      className={clsx(["edit-plate-text", classes.editablePlateText])}
                    />
                  </Grid>
                </Grid>
 
              </Slide>
            </div>
            ) : (<Button alt="Plate Text" title="Plate Text"
              className={clsx(["title-plate-text", classes.titlePlateText, imageRemoved ? "dontshow" : ""])}
              onClick={() => setIsEditable(true)}>
              {updatedPlateText || "No Plate Found"}
            </Button>)}
          </>
        </DialogTitle>
        <DialogContent>
          {imageRemoved ? (
            <>
              <div className={clsx(["image-wrapper", classes.removedImageWrapper])}>
                <div className={clsx(["image-removed", classes.imageRemoved])}>
                  <div>IMAGE HAS</div>
                  <FontAwesomeIcon
                    className={clsx(["animate__animated", "animate__flipInY", classes.removedIcon])}
                    icon={faFilmSlash} size="4x"/>
                  <div>BEEN REMOVED</div>
                </div>
              </div>
            </>
          ) :
            (
              <>
                {open && (
                  <LPRImage lprID={lprID} lprPlateText={updatedPlateText}
                    forCaptureImage={context !== "context"}></LPRImage>
                )}
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <div
            className={clsx(["image-toggle-bar", classes.thumbnailBar, (lprPlateText?.toUpperCase() === "REMOVED") ? "dontshow" : ""])}>
            <div className={clsx(["btn-context-image", classes.thumbnail, (context === "context") ? "selected" : ""])}
              alt="Context Image" title="Context Image" onClick={(e) => setContext("context")}>
              <FontAwesomeIcon size="2x" icon={faImage}/>
            </div>
            <div className={clsx(["btn-capture-image", classes.thumbnail, (context === "capture") ? "selected" : ""])}
              alt="Capture Image" title="Capture Image" onClick={(e) => setContext("capture")}>
              <FontAwesomeIcon size="2x" icon={faCameraCctv}/>
            </div>
          </div>
          <div style={{flex: '1 0 0'}}/>
          <Button className={clsx(["button", "close"])} aria-label="close-button" name="close" variant="contained"
            onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
LPRImageDialog.defaultProps = {
  lprID: null,
  lprPlateText: null,
  toOpen: false,
  onClose: () => {
  },
};
LPRImageDialog.propTypes = {
  lprID: PropTypes.number,
  lprPlateText: PropTypes.string,
  toOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default LPRImageDialog;
