import React, { useState } from "react";
import PropTypes from "prop-types";

/* Components */
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Currency from "../../../Currency";

/* Style */
import clsx from "clsx";

const RevenueSummary = ({ totals }) => {
  const [grossNetToggle, setGrossNetToggle] = useState(
    window.sessionStorage.getItem("GrossNetSetting") ?? "gross"
  );
  const toggleGrossNet = (e) => {
    const { value } = e.target;
    setGrossNetToggle(value);
    window.sessionStorage.setItem("GrossNetSetting", value);
  };

  return (
    <div>
      {grossNetToggle.toLowerCase() === "gross" ? (
        <Typography
          className={clsx("summarized-totals")}
          data-id="summaryTotal"
        >
          <Currency value={totals?.gross?.today ?? 0}></Currency> Today |{" "}
          <Currency value={totals?.gross?.monthToDate ?? 0}></Currency> Last 7 Days
        </Typography>
      ) : (
        <Typography
          className={clsx("summarized-totals")}
          data-id="summaryTotal"
        >
          <Currency value={totals?.net?.today ?? 0}></Currency> Today |{" "}
          <Currency value={totals?.net?.monthToDate ?? 0}></Currency> Last 7 Days
        </Typography>
      )}
      <RadioGroup
        defaultValue="gross"
        name="radio-buttons-gross_net"
        row
        value={grossNetToggle}
        onChange={toggleGrossNet}
      >
        <FormControlLabel
          value="gross"
          control={
            <Radio
              inputProps={{ "aria-label": "Gross" }}
              color="primary"
              size="small"
            />
          }
          label="Gross"
        />
        <FormControlLabel
          value="net"
          control={
            <Radio
              inputProps={{ "aria-label": "Net" }}
              color="primary"
              size="small"
            />
          }
          label="Net"
        />
      </RadioGroup>
    </div>
  );
};

RevenueSummary.propTypes = {
  totals: PropTypes.shape({
    net: PropTypes.shape({
      today: PropTypes.number,
      monthToDate: PropTypes.number,
    }),
    gross: PropTypes.shape({
      today: PropTypes.number,
      monthToDate: PropTypes.number,
    }),
  }),
};

export default RevenueSummary;
